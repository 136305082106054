import React from 'react';
import styled from "styled-components"
import Img from "gatsby-image"
import defImage from "../../images/default.png"
import { colors } from "../../const/colors"

function Index({className, item, handleClick}) {
    return (
        <div onClick={(e) => handleClick(item) } className={className}>
            <div className="image">
                {item.thumbnail ? <Img fluid={item.thumbnail} alt=""/> : <img src={defImage} alt=""/> }

                {/*<img src={item.image.childImageSharp.fluid.src} alt=""/>*/}
            </div>
            <div className="content">
                <h5>{item.locales.title}</h5>
                <p>{item.locales.description}</p>
            </div>
        </div>
    );
}

const PortfolioItem = styled(Index)`
    display:flex;
    position:relative;
    flex-direction: column;
    width:100%;
    overflow: hidden;
    padding-bottom:50px;
  
    &:hover {
      .content {
        bottom: 0;
        box-shadow: 0px 0px 13px 0px #c7c7c7
      }
    }
  margin-bottom: 10px;
   &:hover {
     cursor:pointer;
   }
    @media screen and (min-width: 768px){
      width:48%;
      margin-right:2%;
    }
    @media screen and (min-width: 1147px){
      width:31.33333%;
    }
    .image {
      width:100%;
      height:30%;
      img {
        display:block;
        width:100%;
      }
    }
  h5 {
    //padding:20px;
    height:50px;
    display:flex;
    justify-content: center;
    align-items: center;
    margin-bottom:0;
    color: black;
  }
    .content {
      position:absolute;
      width:100%;
      height:250px;
      display:flex;
      flex-direction: column;
      justify-content: center;
      background:white;
      text-align:center;
      bottom:-200px;
      transition: all .4s ease-in;
      p {
        font-size:16px;
        margin-bottom:0;
        padding:20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height:200px;
      }
    }
`

export default PortfolioItem;
