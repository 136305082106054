import React, {useRef, useState, useEffect} from "react"
import {graphql, useStaticQuery} from "gatsby";
import Layout from "../components/layout"
import SEO from "../components/seo"
import {PortfolioHeader, TabSection} from "../components/PortfolioPage/styles";
// import PortfolioImage from "../images/parallaxbg.jpg"
import {Tab} from "../components/Tabs";
import {PortfolioTabs} from "../components/Tabs/PortfolioTabs";
import  PortfolioList  from "../components/PortfolioList"
import DevGreen from "../images/devgreen.svg"
import DevWhite from "../images/devwhite.svg";
import MarketingGreen from "../images/marketinggreen.svg"
import MarketingWhite from "../images/marketingwhite.svg"
import BrandingGreen from "../images/brandinggreen.svg"
import BrandingWhite from "../images/brandingwhite.svg"
import queryString from "query-string"
import { getSectionsData } from '../utils/helpers'
import localData from "../localization/portfolio.json";

// const flags = {
//     "en": localData.locales[0].value,
//     "es": localData.locales[1].value
// }

const flags = [
    {
        name: "en",
        uri: localData.locales[0].value
    },
    {
        name: "es",
        uri : localData.locales[1].value
    }
]

function Portfolio_list({data, location, pageContext}) {
    const search = queryString.parse(location.search);
    const { lang } = pageContext
    const meta = data?.template?.locales.find(l => l.iso === lang)
    const metaStatic = localData.locales.find(l => l.iso === lang)
    console.log(data)

    // const data = useStaticQuery(graphql`
    //     {
    //         allPortfolioCard {
    //             nodes {
    //                 title
    //                 short_desc
    //                 description
    //                 category
    //                 sub_category
    //                 industry
    //                 services
    //                 tags,
    //                 link {
    //                     label
    //                     src
    //                 }
    //                 headquarter
    //                 imageFile {
    //                     childImageSharp {
    //                         fluid {
    //                             ...GatsbyImageSharpFluid
    //                         }
    //                     }
    //                 }
    //                 slider {
    //                     imageFile {
    //                         childImageSharp {
    //                             fluid {
    //                                 ...GatsbyImageSharpFluid
    //                             }
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // `)
    // const portfolios = data.allPortfolioCard.nodes

    const tempData = getSectionsData(data.template.sections)
    console.log("tempData: ", tempData)

    const GetPortfoliosByCategory = (category, portfolios) => {
        return portfolios.filter( item => item.category.title === category)
    }
    const detailsDivRef = useRef(null)
    const [trigger, setTrigger] = useState(0)
    const [showDetails, setShowDetails] = useState(false)
    const [activeItem, setActiveItem] = useState({
        title: '',
        industry: '',
        short_desc: '',
        description: '',
        image: '',
        slider: '',
        category: '',
        headquarter: '',
        services: '',
        link: '',
        tags: '',
        sub_category: ''
    })
    const allPortfolios = data.allPortfolio.nodes.map(p => {
        const images = p.gallery && p.gallery.galleryImages.length > 0 ? p.gallery.galleryImages.map((gimg, index) => {
            return { ...gimg.childImageSharp.fluid, alt: p.gallery.assets[index].locales.find(l => l.iso === lang).alt}
        }) : null
        const tags = p.tags && p.tags.length > 0 ? p.tags.map(t => {
            return t.locales.find(l => l.iso === lang)
        }) : null
        const additionalData = p.additionalData && p.additionalData.length > 0 ? p.additionalData.reduce((a,c) => {
            return {...a, [c.key]: c.locales.find(l => l.iso === lang).value}
        },{}) : null
        return {
            thumbnail: p.imageFile && p.imageFile.childImageSharp.fluid ,
            portfolioUrl: p.portfolioUrl,
            additionalData: additionalData,
            galleryImages: images,
            locales: p.locales.find(l => l.iso === lang),
            tags: tags,
            category: {
                title: p.category ? p.category.locales.find(l => l.iso === lang)?.title || p.category.locales[0].title : null,
                url: p.category ? p.category.locales.find(l => l.iso === lang)?.value || p.category.locales[0].value : null
            }
        }
    })
    const locCategory = {
        development: {
            en: "Development",
            es: "Desarollo"
        },
        marketing: {
            en: "Digital Marketing",
            es: "Publicidad digital"
        },
        branding: {
            en: "Branding",
            es: "Marca"
        }
    }
    const devPortfolios = GetPortfoliosByCategory(locCategory.development[lang],allPortfolios)
    const brandPortfolios = GetPortfoliosByCategory(locCategory.branding[lang],allPortfolios)
    const markPortfolios = GetPortfoliosByCategory(locCategory.marketing[lang],allPortfolios)
    const handleItemClick = (item) => {
        setActiveItem({
            ...item
        })
        setTrigger(prevState => prevState + 1)
        setShowDetails(true)
        detailsDivRef.current.scrollIntoView({ block: 'start',  behavior: 'smooth' })
    }
console.log("DEV PORTFOLIOS:", allPortfolios)
    return <Layout location={location} lang={lang} flags={flags}>

        <SEO
            title={ meta?.metaTitle || metaStatic.metaTitle}
            description={ meta?.metaDescription || metaStatic.metaDescription}
            keywords={ meta?.metaKeywords || metaStatic.metaKeywords}
        />
        <PortfolioHeader title={localData.pageTitle[lang]} background={tempData.hero.background.image.gapublicURL} />
        {/*<div style={{position:"relative"}}  />*/}
        <TabSection>
            <PortfolioTabs trigger={trigger} searchTerm={search} ref={detailsDivRef} detailedPortfolio list={allPortfolios} activeItem={activeItem} showDetails={showDetails} setShowDetails={setShowDetails}>
                {devPortfolios.length > 0 && <Tab tabIcon={{regular: DevWhite, active: DevGreen}} label={localData.tabs.first.label[lang]} title={localData.tabs.first.title[lang]}>
                    <PortfolioList list={devPortfolios} handleItemClick={handleItemClick} />
                </Tab>}
                {markPortfolios.length > 0 && <Tab tabIcon={{regular: MarketingWhite, active: MarketingGreen}} label={localData.tabs.second.label[lang]} title={localData.tabs.second.title[lang]}>
                    <PortfolioList list={markPortfolios} handleItemClick={handleItemClick} />
                </Tab>}
                {brandPortfolios.length > 0 && <Tab tabIcon={{regular: BrandingWhite, active: BrandingGreen}} label={localData.tabs.third.label[lang]} title={localData.tabs.third.title[lang]}>
                    <PortfolioList list={brandPortfolios} handleItemClick={handleItemClick} />
                </Tab>}
            </PortfolioTabs>
        </TabSection>
    </Layout>
}

export const query = graphql`
    query($templateName: String! ) {
    allPortfolio {
        nodes {
          authorName
          authorUrl
          featured
          portfolioUrl
          additionalData {
            type
            key
            locales {
                iso
                value
            }
          }
          imageFile {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
          }
          category {
            locales {
               iso
               title
            }
          }
          gallery {
            assets {
                locales {
                    iso
                    description
                    title 
                    alt
                  }
                }
            galleryImages {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          locales {
            content
            description
            iso
            title
            value
          }
        }
      }
        template(name: {eq: $templateName}) {
          locales {
              value
              metaDescription
              metaKeywords
              metaTitle
            }
            sections {
                keyPrefix
                items {
                    key
                    ... on DataItemTextGet {
                        __typename
                        locales {
                            iso
                            value
                        }
                    }
                    ... on DataItemAssetGet {
                        __typename
                        imageFile {
                            childImageSharp {
                                fluid(quality: 90, maxWidth: 1920) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                            publicURL
                        }
                        value {
                            extension
                            locales {
                                alt
                                description
                                iso
                                title
                            }
                        }
                    }
                }
            }
        }
    }
`

export default Portfolio_list;
