import React, {useState} from 'react';
import PortfolioItem from "../PortfolioItem";
import styled from "styled-components"


function Index({className, list, handleItemClick}) {
    return (
        <div className={className}>
            {
                list.map((pitem, index) => {
                    return <PortfolioItem key={index} item={pitem} handleClick={handleItemClick} />
                })
            }
        </div>
    );
}

const PortfolioList = styled(Index)`
    display:flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap:wrap;
    max-width:1170px;
    margin:auto;
    padding:25px;
`

export default PortfolioList;